import React from 'react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import GaugeChart from 'react-gauge-chart'
import { Column } from "primereact/column";
import { Tooltip } from 'primereact/tooltip';
import { FiPlusCircle } from "react-icons/fi";
import { InputText } from 'primereact/inputtext';
import { TbReportSearch } from "react-icons/tb";
import { AiOutlineUpload } from "react-icons/ai";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { FaRegFileLines } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import JDService from "../../../services/jd.service";
import { BsThreeDotsVertical } from "react-icons/bs";
import flagIcon from './../../../assets/icons/flag.svg';
import codeIcon from './../../../assets/icons/code.svg';
import { getPaginationLimit } from "../../../utils/common";
import { useLocation, useNavigate } from "react-router-dom";
import ResumeService from "../../../services/resume.service";
import { useAuth } from '../../../contextProviders/AuthContext';
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useToast } from "../../../contextProviders/ToastContext";
import { RESUME_STAGES, RESUME_STATUS } from "../../../utils/constants";
import AddResumeLessPopup from "../../../components/Resume/AddResumeLessPopup";
import ResumesUploadPopup from "../../../components/Resume/ResumesUploadPopup";
import ResumesSelectPopup from '../../../components/Resume/ResumesSelectPopup';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import ConsoleHelper from '../../../utils/consoleHelper';

const REFRESH_INTERVAL = 3000;

const JDDetailsResumes = React.memo((props) => {
    const {user} = useAuth();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const intervalResumeInQueueRef = useRef(null);
    const intervalResumeShortListRef = useRef(null);

    const [first, setFirst] = useState(0);
    const [resumes, setResumes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const {menuVisibility, handleMenuVisibility} = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(getPaginationLimit());
    const {showErrorMessage, showSuccessMessage} = useToast();
    const [selectedResumes, setSelectedResumes] = useState(null);

    const [expandedRows, setExpandedRows] = useState(null);

    const [rows, setRows] = useState(getPaginationLimit());
    const [selectedResume, setSelectedResume] = useState(null);

    const jdId = pathname.replace('/dashboard/job-description/', '');

    const [showResumesSelectPopup, setShowResumesSelectPopup] = useState(false);
    const [showResumelessPopup, setShowResumelessPopup] = useState(false);
    const [showResumesUploadPopup, setShowResumesUploadPopup] = useState(false);

    const onInviteDropdownClick = (event) => {
        event.stopPropagation();
        handleMenuVisibility(true);
    }

    useEffect(() => {
        const handleResize = () => {
            setLimit(getPaginationLimit())
            setRows(getPaginationLimit())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const refreshQueuedResumes = useCallback(async () => {
        try {
            const queuedResumeIds = resumes
                .filter(item => item?.stage === RESUME_STAGES.IN_QUEUE)
                .map(item => item?._id);

            if (queuedResumeIds.length === 0) return;
    
            const response = await ResumeService.getResumesByIds(queuedResumeIds);
            if (response.status === 200) {
                    const updatedResumes = response?.data?.data || [];
                    setResumes(prev => prev.map(resume => {
                    const data = updatedResumes.find(r => r._id === resume._id);
                    const isUpdated = resume?.stage !== data?.stage;
                    return isUpdated ? { ...resume, ...data } : resume;
                }));
        
                const hasQueuedResumes = updatedResumes.some(resume => resume?.stage === RESUME_STAGES.IN_QUEUE);
                if (!hasQueuedResumes && intervalResumeInQueueRef.current) {
                    clearInterval(intervalResumeInQueueRef.current);
                    intervalResumeInQueueRef.current = null;
                    ConsoleHelper.log('All resumes processed. Stopping auto-refresh.');
                }
            }
        } catch (error) {
            ConsoleHelper.error(`Error refreshing queued resumes: ${JSON.stringify(error)}`);
        }
    }, [resumes]);

    const refreshShortlistInProgressResumes = useCallback(async () => {
        try {
            const resumeIds = resumes
                .filter(item => item?.status === RESUME_STATUS.SHORTLISTING_IN_PROGRESS)
                .map(item => item?._id);

            if (resumeIds.length === 0) return;
    
            const response = await ResumeService.getResumesByIds(resumeIds);
            if (response.status === 200) {
                const updatedResumes = response?.data?.data || [];
                setResumes(prev => prev.map(resume => {
                    const data = updatedResumes.find(r => r._id === resume._id);
                    const isUpdated = resume?.status !== data?.status;
                    return isUpdated ? { ...resume, ...data } : resume;
                }));
        
                const hasInProgressResumes = updatedResumes.some(resume => resume?.status === RESUME_STATUS.SHORTLISTING_IN_PROGRESS);
                if (!hasInProgressResumes && intervalResumeShortListRef.current) {
                    clearInterval(intervalResumeShortListRef.current);
                    intervalResumeShortListRef.current = null;
                    ConsoleHelper.log('Stopping auto-refresh.');
                }
            }
        } catch (error) {
            ConsoleHelper.error(`Error refreshing in progress resumes: ${JSON.stringify(error)}`);
        }
    }, [resumes]);

    const getResumes = async () => {
        try {
            setLoading(true);
            const response = await ResumeService.getJDResumes(jdId, currentPage, limit);
            setLoading(false);
            if(response.status === 200){
                const {data, totalCount} = response.data || []; 
                const list = data?.map((item) => ({...item, selected: false}))
                setResumes(list);
                setTotalCount(totalCount);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getResumes();
    }, [currentPage, limit]);

    useEffect(() => {
        intervalResumeInQueueRef.current = setInterval(refreshQueuedResumes, REFRESH_INTERVAL);
        return () => {
          if (intervalResumeInQueueRef.current) {
            clearInterval(intervalResumeInQueueRef.current);
          }
        };
    }, [refreshQueuedResumes]);

    useEffect(() => {
        intervalResumeShortListRef.current = setInterval(refreshShortlistInProgressResumes, REFRESH_INTERVAL);
        return () => {
          if (intervalResumeShortListRef.current) {
            clearInterval(intervalResumeShortListRef.current);
          }
        };
    }, [refreshShortlistInProgressResumes]);

    function isBelongsToAlorica() {
        return user?.email?.includes('alorica') ? true: false
    }

    const reload =  async () => {
       await getResumes();
    }

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    const resumeScreening = async () => {
        try {
            setLoading(true);
            const resumeIds = selectedResumes?.map((item) => item?._id)
            const response = await ResumeService.sortlistResumes(jdId, resumeIds);
            setLoading(false);
            if(response.status === 200){
                setSelectedResumes(null);
                await getResumes();
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
    }

    const onRowEditComplete = async (e) => {
        try {
            let products = [...resumes];
            let { newData, index } = e;
            const email = newData['email'];
            const name = newData['name'];

            const meta = {...newData.meta};
            if(name) meta['Name'] = name;
            if(email) meta['Email'] = email;

            const requestData = {};
            if(name) requestData['name'] = name;
            if(email) requestData['email'] = email?.trim();
            if(Object.keys(requestData).length){
                const response = await ResumeService.updateResume(newData?._id, requestData);
                if(response.status === 200){              
                    products[index] = { ...newData, meta: meta };
                    setResumes(products);
                    showSuccessMessage({ summary: 'Updated', detail: "Updated resume" })
                }
            }
        } catch (error) {
            showErrorMessage({ summary: "Failed", detail: 'Failed to update resume'})
        }
    };

    const sendInterviewInviteAll = async (interviewType) => {
        try {
            setLoading(true);
            const response = await JDService.sendInterviewInvite(jdId, {
                interviewType: interviewType,
                resumeIds: selectedResumes?.map((item) => item?._id)
            })
            setLoading(false);
            if(response.status === 200){
                showSuccessMessage({
                    summary: 'Invites sent', 
                    detail: interviewType === RESUME_STAGES.INITIAL_SCREENING ?  'Initial Interview invite sent to all' : 
                        interviewType === RESUME_STAGES.OPS_SCREENING ?  'OPS Interview invite sent to all': 'Technical Interview invite sent to all'
                });
                setSelectedResumes(null);
                await getResumes();
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: "Failed", detail: 'Failed to sent invite'})
        }
    }

    const sortlistResume = async (resumeId) => {
        try {
            setLoading(true);
            const response = await ResumeService.sortlistResumes(jdId, [resumeId]);
            setLoading(false);
            setSelectedResume(null);
            if(response.status === 200) {
                await getResumes();
                setSelectedResume(null);
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message })
        }
    }

    const sendInterviewInvite = async (resumeId, meta, interviewType) => {
        try {
            setLoading(true);
            const hasEmail = meta['Email']?.includes('No') || meta['Email']?.includes('N/A') ? false: true;
            if(!hasEmail){
                showErrorMessage({summary: 'Required', detail: 'Email is required'})
                return;
            }
            const response = await JDService.sendInterviewInvite(jdId, {
                resumeIds: [resumeId],
                interviewType: interviewType,
            }) 
            setLoading(false);
            if(response.status === 200){
                showSuccessMessage({
                    summary: 'Invite Sent', 
                    detail: interviewType === RESUME_STAGES.INITIAL_SCREENING ?  'Initial Interview invite sent': 
                    interviewType === RESUME_STAGES.OPS_SCREENING ? 'OPS Interview invite sent': 'Technical Interview invite sent'
                })
                setSelectedResume(null);
                await getResumes();
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({ summary: "Failed", detail: 'Failed to sent invite'})
        }
    }

    const allowEdit = (rowData) => {
        return rowData?.stage !== RESUME_STAGES.IN_QUEUE;
    };

    const textEditor = (options) => {
        const data = options?.rowData?.meta;
        if(!data) return;

        let defaultValue = null;
        if(options.field === 'name')
            defaultValue = data['Name'];
        else if(options.field === 'email')
            defaultValue = data['Email'];
        return (
            <InputText 
                type="text" 
                value={options?.value} 
                defaultValue={defaultValue}
                className='border border-primary px-1 h-10'
                onChange={(e) => options.editorCallback(e.target.value)} 
            />
        )
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
    };

    const renderEmail = ({meta, _id}) => {
        const dynamicClass = `tooltip-${_id}`;
        const email = meta?.['Email'] || meta?.['email'];

        return (
            <div className="flex gap-4 items-center w-40" onClick={handleCopy.bind(this, email)}>
                <Tooltip target={`.${dynamicClass}`} position="bottom">
                    <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                        <h3 className='text-xs'>{email}</h3>
                        <span className='text-xs text-blue'>Click on email to copy</span>
                    </div>
                </Tooltip>
                <span className={`text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer ${dynamicClass}`}>{email}</span>
            </div>
        )
    }

    const renderName = ({meta}) => {
        return (
            <div className="flex gap-4 items-center w-32">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {meta?.['Name'] || meta?.['name']}
                </span>
            </div>
        )
    }

    const renderStatus = (props) => {
        const {initialScreening, stage, status, technicalScreening, opsScreening } = props;
        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {stage === RESUME_STAGES.INITIAL_SCREENING ? initialScreening?.status : stage === RESUME_STAGES.TECHNICAL_SCREENING ? technicalScreening?.status : stage === RESUME_STAGES.OPS_SCREENING ? opsScreening?.status : status}
                </span>
            </div>
        )
    }

    const renderScore = useCallback(({_id, resumeScreening, stage, status}) => {
        if(stage === RESUME_STAGES.IN_QUEUE || stage === RESUME_STAGES.RESUME_RECEIVED || 
            (stage === RESUME_STAGES.RESUME_SCREENING && status === RESUME_STATUS.SHORTLISTING_IN_PROGRESS)
        ) 
            return <span>NA</span>
        return (
            <div className="flex items-center gap-5">
                <div className="flex flex-col items-center justify-center gap-2">
                    <GaugeChart 
                        id="gauge-chart1" 
                        nrOfLevels={4}
                        hideText={true}
                        style={{ width: 40 }}
                        colors={["#FF3B30", "#FF9500", '#FED000', '#4DC284']} 
                        percent={parseInt(resumeScreening?.['meta']?.['score']) / 100} 
                    />
                    <span className="text-sm">{resumeScreening?.['meta']?.['score'] } / 100</span>
                </div>
                <div 
                    onClick={() => navigate(`/dashboard/reports/${_id}`)}
                    className="h-8 w-8 rounded-full justify-center items-center flex cursor-pointer" 
                    style={{backgroundColor: 'rgba(60, 153, 202, 0.1)'}}>
                    <FaRegFileLines size={16} color="#3C99CA" />
                </div>
            </div>
        )
    }, []);

    const options = (item) => {
        return (
            <div 
                onClick={(event) => event?.stopPropagation()}
                className={`absolute transform -translate-x-1/2 -translate-y-1/2 ${props.jd?.active === false ? 'hidden': 'visible'}`}>
                <div className={`${selectedResume?._id === item?._id ? 'visible' : 'hidden'} rounded-lg  shadow-gray shadow bg-white z-50 -top-12 right-0 absolute`}>
                    <ul className="p-2 gap-2 flex flex-col w-48 text-sm">
                        <li 
                            // className={`py-2 text-[#FF3B30] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer
                            //      ${selectedResume?.stage === RESUME_STAGES.RESUME_RECEIVED ? 'visible' : 'hidden'}
                            // `}
                            className='py-2 text-[#FF3B30] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer'
                            onClick={sortlistResume.bind(this, item?._id)} 
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                            <TbReportSearch size={16}/>
                            <h4 className='text-sm'>Resume Screening</h4>
                        </li>
                        <li 
                            style={{backgroundColor: 'rgba(255, 149, 0, .1)'}}
                            className={`
                                text-[#FF9500] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer
                                ${(selectedResume?.stage === RESUME_STAGES.IN_QUEUE || selectedResume?.stage === RESUME_STAGES.RESUME_RECEIVED) ? 'hidden' : 'visible'}
                            `}
                            onClick={sendInterviewInvite.bind(this, item?._id, item?.meta, RESUME_STAGES.INITIAL_SCREENING)} >
                            <img alt="flag-icon" src={flagIcon} className="h-4 w-4" />
                            <span>Send Initial Invite</span>
                        </li>
                        <li 
                            style={{backgroundColor: 'rgba(22, 93, 255, .1)'}}
                            className={`
                                text-[#165DFF] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer
                                ${(selectedResume?.stage === RESUME_STAGES.IN_QUEUE || selectedResume?.stage === RESUME_STAGES.RESUME_RECEIVED || isBelongsToAlorica()) ? 'hidden' : 'visible'}
                            `}
                            onClick={sendInterviewInvite.bind(this, item?._id, item?.meta, RESUME_STAGES.TECHNICAL_SCREENING)} >
                            <img alt="code-icon" src={codeIcon} className="h-4 w-4"
                            />
                            <span>Send Tech Invite</span>
                        </li>
                        <li 
                            style={{backgroundColor: 'rgba(22, 93, 255, .1)'}}
                            onClick={sendInterviewInvite.bind(this, item?._id, item?.meta, RESUME_STAGES.OPS_SCREENING)}
                            className={`text-[#165DFF] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer
                                ${(selectedResume?.stage === RESUME_STAGES.IN_QUEUE || selectedResume?.stage === RESUME_STAGES.RESUME_RECEIVED || !isBelongsToAlorica()) ? 'hidden' : 'visible'}
                            `}>
                            <img alt="code-icon" src={codeIcon} className="h-4 w-4"/>
                            <span>OPS Invite</span>
                        </li>
                    </ul>
                </div>
                <BsThreeDotsVertical
                    size={20} 
                    color='#666666'
                    className={`cursor-pointer ${item?.stage === RESUME_STAGES.IN_QUEUE ? 'hidden': 'visible'}`}
                    onClick={() => setSelectedResume(item)}
                />
            </div>
        )
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="flex gap-10 justify-center">
                <div className='flex items-center'>
                    <h2 className='text-sm font-normal text-brownGray'>Resume Screening:</h2>
                    <span className='ml-2 font-semibold text-sm'>{data?.resumeScreening?.status}</span>
                </div>
                <div className='flex items-center'>
                    <h2 className='text-sm font-normal text-brownGray'>Initial Screening:</h2>
                    <span className='ml-2 font-semibold text-sm'>{data?.initialScreening?.status}</span>
                </div>
                <div className={`flex items-center ${!isBelongsToAlorica() ? 'visible': 'hidden'}`}>
                    <h2 className='text-sm font-normal text-brownGray'>Technical Screening:</h2>
                    <span className='ml-2 font-semibold text-sm'>{data?.technicalScreening?.status}</span>
                </div>
                <div className={`flex items-center ${isBelongsToAlorica() ? 'visible': 'hidden'}`}>
                    <h2 className='text-sm font-normal text-brownGray'>Ops Screening:</h2>
                    <span className='ml-2 font-semibold text-sm'>{data?.opsScreening?.status}</span>
                </div>
            </div>
        );
    };

    return (
        <section
            className='flex-1 mt-5' 
            onClick={() => {
                handleMenuVisibility(false)
                setSelectedResume(null);
            }}>
            <div className={`${showResumesSelectPopup ? 'visible' : 'hidden'}`}>
                <ResumesSelectPopup 
                    reload={reload}
                    hide={() => setShowResumesSelectPopup(false)}
                />
            </div>
            <div className={`${showResumelessPopup ? 'visible' : 'hidden'}`}>
                <AddResumeLessPopup 
                    hide={() => setShowResumelessPopup(false)}
                />
            </div>
            <div className={`${showResumesUploadPopup ? 'visible' : 'hidden'}`}>
                <ResumesUploadPopup 
                    getResumes={getResumes} 
                    hide={() => setShowResumesUploadPopup(false)} 
                />
            </div>
            <div className="flex justify-between items-center relative z-30">
                <h2 className="font-semibold text-xl text-darkGray">Resumes</h2>
                <div className={`flex items-center gap-5 text-brownGray ${props.jd?.active === false ? 'hidden': 'visible'}`}>
                    {/* <button 
                        onClick={() => getResumes()}
                        className="bg-white h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer">
                        <span className="text-sm">Refresh</span>
                        <MdRefresh size={20} />
                    </button> */}
                    <button 
                        onClick={() => setShowResumesSelectPopup(true)}
                        className="bg-white h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer">
                        <span className="text-sm">Select Resumes</span>
                        {/* <MdRefresh size={20} /> */}
                    </button>
                    <button 
                        onClick={() => setShowResumesUploadPopup(true)}
                        className="bg-white h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer">
                        <span className="text-sm">Upload resume</span>
                        <AiOutlineUpload size={20} />
                    </button>
                    <button
                        // onClick={() => {setShowResumelessPopup(true)}}
                        className="bg-white opacity-40 h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer add-resume">
                        <span className="text-sm">Add resume</span>
                        <FiPlusCircle size={20} />
                    </button>
                    <Tooltip 
                        target='.add-resume'
                        position="bottom">
                        <span className='text-sm font-semibold'>For resume less only</span>
                    </Tooltip>
                    <div 
                        onClick={onInviteDropdownClick}
                        className={`bg-white h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer ${resumes?.length ? 'visible': 'hidden'}`}>
                        <span className="text-sm">Take next action</span>
                        {menuVisibility ? <MdOutlineKeyboardArrowUp size={22} /> : <MdOutlineKeyboardArrowDown size={22} />}
                        <div 
                            onClick={(event) => event.stopPropagation()}
                            className={`rounded-md absolute shadow shadow-gray -bottom-28 right-0 bg-white ${menuVisibility ? 'visible': 'hidden'}`}>
                           <ul className="flex flex-col gap-3 p-2 text-sm flex-wrap w-48">
                                <li
                                    onClick={() => {
                                        if(selectedResumes?.length > 0)
                                            resumeScreening();
                                        else showErrorMessage({summary: 'Required', detail: 'Please select resumes'})
                                        handleMenuVisibility(false);
                                    }} 
                                    style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}
                                    className={`text-[#FF3B30] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer`}>
                                    <TbReportSearch size={16}/>
                                    <span>Resume Screening</span>
                                </li>
                                <li
                                    onClick={() => {
                                        if(selectedResumes?.length > 0)
                                            sendInterviewInviteAll(RESUME_STAGES.INITIAL_SCREENING)
                                        else showErrorMessage({summary: 'Required', detail: 'Please select resumes'})
                                        handleMenuVisibility(false);
                                    }} 
                                    style={{backgroundColor: 'rgba(255, 149, 0, .1)'}}
                                    className={`text-[#FF9500] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer`}>
                                    <img alt="flag-icon" src={flagIcon} className="h-4 w-4" />
                                    <span>Send Initial Invite</span>
                                </li>
                                <li 
                                    onClick={() => {
                                        if(selectedResumes?.length > 0)
                                            sendInterviewInviteAll(RESUME_STAGES.TECHNICAL_SCREENING);
                                        else showErrorMessage({summary: 'Required', detail: 'Please select resumes'})
                                        handleMenuVisibility(false);
                                    }} 
                                    style={{backgroundColor: 'rgba(22, 93, 255, .1)'}}
                                    className={`text-[#165DFF] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer ${!isBelongsToAlorica() ? 'visible': 'hidden'}`}>
                                    <img alt="code-icon" src={codeIcon} className="h-4 w-4"/>
                                    <span>Send Tech Invite</span>
                                </li>
                                <li 
                                    onClick={() => {
                                        if(selectedResumes?.length > 0)
                                            sendInterviewInviteAll(RESUME_STAGES.OPS_SCREENING);
                                        else showErrorMessage({summary: 'Required', detail: 'Please select resumes'})
                                        handleMenuVisibility(false);
                                    }} 
                                    style={{backgroundColor: 'rgba(22, 93, 255, .1)'}}
                                    className={`text-[#165DFF] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer ${isBelongsToAlorica() ? 'visible': 'hidden'}`}>
                                    <img alt="code-icon" src={codeIcon} className="h-4 w-4"/>
                                    <span>OPS Invite</span>
                                </li>
                           </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white rounded mt-5">
                <DataTable
                    dataKey="_id"
                    editMode="row" 
                    value={resumes}
                    loading={loading}
                    rowClassName="bg-white"
                    selectionMode='checkbox'
                    expandedRows={expandedRows} 
                    className="text-brownGray"
                    selection={selectedResumes}
                    onRowEditComplete={onRowEditComplete}
                    emptyMessage="No resume available"  
                    rowExpansionTemplate={rowExpansionTemplate}
                    onSelectionChange={(e) => setSelectedResumes(e.value)}>
                    <Column 
                        selectionMode="multiple" 
                        headerClassName='max-w-5'
                        className='max-w-10'
                    />
                    <Column 
                        expander={true} 
                        className='max-w-10' 
                        body={(item) => (
                            <div>
                                <IoIosArrowForward
                                    onClick={() => setExpandedRows({[item?._id]: true})} 
                                    className={`check-prior-status cursor-pointer ${expandedRows?.[item?._id] ? 'hidden': 'visible'}`}
                                />
                                <IoIosArrowDown
                                    onClick={() => setExpandedRows(null)} 
                                    className={`check-prior-status cursor-pointer ${expandedRows?.[item?._id] ? 'visible': 'hidden'}`}
                                />
                                 <Tooltip 
                                    target='.check-prior-status'
                                    position="right">
                                    <span className='text-sm font-semibold'>
                                        Check Prior Status
                                    </span>
                                </Tooltip>
                            </div>
                        )}
                    />
                    <Column  
                        header="Name" 
                        field="name" 
                        body={renderName}
                        className='text-sm 2xl:text-base'
                        editor={(options) => textEditor(options)}
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        header="Email" 
                        field="email" 
                        body={renderEmail}
                        className='text-sm 2xl:text-base'
                        editor={(options) => textEditor(options)}
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    {/* <Column 
                        header="Mobile number" 
                        body={renderMobileNumber}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
                    <Column 
                        field="stage" 
                        header="Stage" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        header="Score"
                        body={renderScore} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="status" 
                        header="Status"
                        body={renderStatus}  
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        body={options}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        rowEditor={allowEdit} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white'>
                    </Column>
                </DataTable>
            </div>
            <Paginator 
                rows={rows}
                first={first}
                totalRecords={totalCount}
                onPageChange={onPageChange}
                className={`bg-[#ECF5FA] justify-end mt-5 ${totalCount / rows > 1 ? 'visible': 'hidden'}`}
            />
        </section>
    )
});

JDDetailsResumes.propTypes = {
    menuVisibility: PropTypes.bool,
    handleMenuVisibility: PropTypes.func
}

export default JDDetailsResumes;